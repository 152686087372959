import React, { useEffect, useState, useContext } from "react"
import {
    Box,
    Flex,
    Grid,
    GridItem,
    Heading,
    Text,
    Divider,
    Button,
    Input,
    FormLabel,
    FormControl,
    useToast,
    Link,
} from "@chakra-ui/react"
import { format } from "date-fns"

import { UserContext } from "~config/user-context"
import { Notification } from "~models"
import { subscribeToNotifications } from "~queries"

const Notifications = () => {
    const { user } = useContext(UserContext)

    const [notifs, setNotifs] = useState<Notification[]>([])

    useEffect(() => {
        const subscriber = subscribeToNotifications(user!.id, snapshot => {
            const tmpNotifs: Notification[] = []
            snapshot.forEach(notification => {
                if (notification.data().message)
                    tmpNotifs.push(notification.data() as Notification)
            })

            setNotifs(tmpNotifs)
        })

        return () => subscriber()
    }, [])

    return (
        <Flex direction="column" gridGap={8}>
            {notifs.map(notif => (
                <Box
                    key={notif.id}
                    borderRadius={20}
                    bg="rgba(170,170,170,0.1)"
                    w="100%"
                    p={4}
                >
                    <Flex justify="space-between">
                        <Text fontSize={"sm"} color="gray.400">
                            {notif.type}
                        </Text>
                        <Text>
                            {format(
                                new Date(notif.createdAt),
                                "MMM dd, yyyy 'at' h:mmaaa"
                            )}
                        </Text>
                    </Flex>
                    <Text mb={4}>{notif.message}</Text>
                    <Link>{notif.link}</Link>
                </Box>
            ))}
        </Flex>
    )
}

export default Notifications
