import React, { useState, useContext, useEffect } from "react"
import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	Text,
	Divider,
	Button,
	Input,
	FormLabel,
	FormControl,
	useToast,
} from "@chakra-ui/react"

import { UserContext } from "~config/user-context"
import ResponsiveBlock from "~components/shared/responsive-block"
import Notifications from "~components/account/notifications"
import { updateUser } from "~queries"

const AccountPage = () => {
	const { user } = useContext(UserContext)
	const toast = useToast()

	const [email, setEmail] = useState(user.email)
	const [username, setUsername] = useState(user.username)
	const [team, setTeam] = useState(user.team)

	const [canUpdateUser, setCanUpdate] = useState(false)

	useEffect(() => {
		const canUpdate = username !== user.username || team !== user.team

		setCanUpdate(canUpdate)
	}, [username, team])

	return (
		<ResponsiveBlock>
			<Grid gridGap={8} templateColumns={"repeat(3, 1fr)"}>
				<GridItem colSpan={[3, 3, 2, 2]}>
					<FormControl>
						<Heading fontSize="lg" color="teal">
							Profile
						</Heading>
						<Divider mb={8} />
						<FormLabel>Email</FormLabel>
						<Input
							type="text"
							value={email}
							disabled
							color="gray.400"
							mb={4}
						/>
						<FormLabel>Username</FormLabel>
						<Input
							type="text"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							mb={4}
						/>
						<FormLabel>Team</FormLabel>
						<Input
							type="text"
							value={team}
							onChange={(e) => setTeam(e.target.value)}
							mb={4}
						/>
						{canUpdateUser && (
							<Button
								onClick={async () => {
									const success = await updateUser(user.id, {
										username,
										team,
									})
									setCanUpdate(false)
									if (success)
										toast({
											title: "Updated user!",
											status: "success",
										})
									else
										toast({
											title: "Failed to update user.",
											status: "error",
										})
								}}>
								Update User
							</Button>
						)}
					</FormControl>
				</GridItem>
				<GridItem colSpan={[3, 3, 1, 1]}>
					<Box>
						<Heading fontSize="lg" color="teal">
							Notifications
						</Heading>
						<Divider mb={8} />
						<Notifications />
					</Box>
				</GridItem>
			</Grid>
		</ResponsiveBlock>
	)
}

export default AccountPage
